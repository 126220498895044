@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?1lxg44');
  src:  url('fonts/icomoon.eot?1lxg44#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?1lxg44') format('truetype'),
    url('fonts/icomoon.woff?1lxg44') format('woff'),
    url('fonts/icomoon.svg?1lxg44#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pin:before {
  content: "\e900";
}
.icon-email:before {
  content: "\e901";
}
.icon-magnifying-glass:before {
  content: "\e902";
}
.icon-successful:before {
  content: "\e903";
}
.icon-loan:before {
  content: "\e904";
}
.icon-confirmation:before {
  content: "\e905";
}
.icon-smartphone:before {
  content: "\e906";
}
.icon-operation:before {
  content: "\e907";
}
.icon-payment-gateway:before {
  content: "\e908";
}
.icon-tick:before {
  content: "\e909";
}
.icon-diamond:before {
  content: "\e90a";
}
.icon-car:before {
  content: "\e90b";
}
.icon-bank:before {
  content: "\e90c";
}
.icon-house:before {
  content: "\e90d";
}
.icon-payment:before {
  content: "\e90e";
}
.icon-smartphone-1:before {
  content: "\e90f";
}
.icon-telephone:before {
  content: "\e910";
}
.icon-select:before {
  content: "\e911";
}
.icon-bill:before {
  content: "\e912";
}
.icon-growth:before {
  content: "\e913";
}
.icon-briefcase:before {
  content: "\e914";
}
.icon-money-transfer:before {
  content: "\e915";
}
.icon-credit-cards:before {
  content: "\e916";
}
.icon-bank-transfer:before {
  content: "\e917";
}
.icon-audit:before {
  content: "\e918";
}
.icon-portfolio:before {
  content: "\e919";
}
.icon-education:before {
  content: "\e91a";
}
.icon-settings:before {
  content: "\e91b";
}
.icon-quotes:before {
  content: "\e91c";
}
.icon-credibility:before {
  content: "\e91d";
}
.icon-book:before {
  content: "\e91e";
}
.icon-user:before {
  content: "\e91f";
}
.icon-phone-ringing:before {
  content: "\e920";
}
.icon-management:before {
  content: "\e921";
}
.icon-designer:before {
  content: "\e922";
}
.icon-programmer:before {
  content: "\e923";
}
.icon-flowchart:before {
  content: "\e924";
}
.icon-phone:before {
  content: "\e925";
}
